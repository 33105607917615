.buttonWrapper {
  z-index: 2;
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 50px;
  height: 50px;
  background-color: var(--background-primary);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 100px 2px rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}
/* .listWrapper:hover, */
.buttonWrapper:hover + .listWrapper,
.listWrapper:hover {
  transition: 0.6s;
  transform: translateX(0px);
  /* width: 200px; */
}
.listWrapperLink {
  transform: translateX(-200px);
  transition: 0.6s;
  color: var(--text-primary);
}
.listWrapperLink:hover {
  font-size: 20px;
  transition: 0.3s;
}
.buttonWrapper:hover .listWrapperLink,
.listWrapper:hover .listWrapperLink,
.active .listWrapperLink {
  transform: translateX(0);
}
.listWrapper {
  padding: 0;
  z-index: 3;
  /* width: 250px; */
  height: 100%;
  background-color: var(--background-primary);
  position: fixed;
  width: 200px;
  z-index: 10;
  transform: translateX(-200px);
  transition: 0.6s transform;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 1em;
  letter-spacing: 0.2em;
}
.listWrapper:hover,
.buttonWrapper:hover + .listWrapper {
  /* width: 200px; */
  padding: 2em;
  transition: 0.6s;
  box-sizing: border-box;
}
