.outerspace {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 1.5px;
  width: 1.5px;
  background-color: var(--text-primary);
  border-radius: 50%;
  box-shadow: -25vw 14vh 0px 0px var(--text-primary),
    -45vw -24vh 1px 1px var(--text-primary),
    29vw 27vh 1px 1px var(--text-primary),
    44vw -33vh 0px 0px var(--text-primary),
    38vw 48vh 0px 0px var(--text-primary),
    -4vw -30vh 0px 1px var(--text-primary),
    32vw -38vh 1px 1px var(--text-primary),
    -19vw -15vh 1px 0px var(--text-primary),
    22vw 43vh 0px 1px var(--text-primary), 2vw 20vh 1px 1px var(--text-primary),
    32vw -23vh 1px 0px var(--text-primary),
    -36vw 29vh 1px 0px var(--text-primary),
    44vw 10vh 1px 1px var(--text-primary),
    35vw -21vh 1px 1px var(--text-primary),
    -26vw -23vh 0px 1px var(--text-primary),
    -38vw -9vh 1px 1px var(--text-primary),
    -50vw -41vh 0px 0px var(--text-primary),
    -33vw 45vh 0px 1px var(--text-primary),
    -10vw -31vh 0px 1px var(--text-primary),
    -18vw -33vh 1px 0px var(--text-primary),
    -14vw -32vh 1px 1px var(--text-primary),
    6vw 28vh 0px 1px var(--text-primary), 39vw 29vh 0px 0px var(--text-primary),
    -37vw 22vh 0px 0px var(--text-primary), 3vw 26vh 0px 1px var(--text-primary),
    14vw 18vh 0px 1px var(--text-primary),
    -19vw -44vh 1px 1px var(--text-primary),
    -2vw 33vh 0px 1px var(--text-primary), -47vw 5vh 1px 1px var(--text-primary),
    -14vw 25vh 1px 0px var(--text-primary),
    -27vw -21vh 1px 0px var(--text-primary),
    -43vw 3vh 1px 1px var(--text-primary), 43vw 4vh 1px 1px var(--text-primary),
    44vw -29vh 1px 1px var(--text-primary),
    -32vw 29vh 0px 0px var(--text-primary),
    45vw 28vh 0px 1px var(--text-primary), 47vw 18vh 0px 1px var(--text-primary),
    16vw -14vh 0px 1px var(--text-primary), 8vw -1vh 1px 1px var(--text-primary),
    38vw -35vh 1px 0px var(--text-primary),
    14vw -46vh 0px 1px var(--text-primary),
    -24vw 47vh 0px 1px var(--text-primary),
    20vw 46vh 1px 0px var(--text-primary), -2vw -6vh 0px 1px var(--text-primary),
    12vw 11vh 1px 0px var(--text-primary), -3vw 28vh 0px 1px var(--text-primary),
    -38vw -11vh 1px 1px var(--text-primary),
    -49vw 35vh 1px 1px var(--text-primary),
    -30vw -18vh 1px 1px var(--text-primary),
    25vw 15vh 0px 0px var(--text-primary);
  animation: spacetravel 10s alternate infinite;
  filter: blur(0.1px);
}
@keyframes spacetravel {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
