.wrapper {
  position: relative;
  width: 32px;
  height: 16px;
  background: gray;
  border-radius: 3em;
  transition: 0.3s;
}
.wrapper[data-color-display="light"] {
  background: linear-gradient(
    to right,
    #ff6b6b,
    #ffd166,
    #74b9ff,
    #55e6c1,
    #81ecec
  );
}
.wrapper[data-color-display="dark"] {
  background: linear-gradient(to right, #141414, #1a1a1a, #2c3e50);
}

.slideToggleCircle {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: block;
  content: "";
  background-color: var(--text-primary);
  border-radius: 50%;
  transition: 0.4s;
  width: 16px;
  height: 16px;
}
.slideToggleCircleTrue {
  transform: translate(16px);
}
.slideToggleLabel {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.slideToggleInput {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
