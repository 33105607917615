.wrapper {
  z-index: 1;
}
.flexGrow {
  flex-grow: 1;
}
.wrapper form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.formShadowWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  box-shadow: 0px 0px 45px 1px rgba(255, 255, 255, 0.3);
}
.inputRow {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.topHeaderWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 2em 0.5em;
  gap: 1em;
}
.topHeaderWrapper h1 {
  margin: 0;
}
.topHeaderWrapperImgWrapper {
  border-radius: 50%;
  overflow: hidden;
  min-width: 150px;
  height: 150px;
  /* box-shadow: 0px 0px 50px 1px rgba(255, 255, 255, 0.3); */
}
.topHeaderWrapperImg {
  width: 150px;
  height: 150px;
  position: relative;
  background-color: var(--background-primary);
  z-index: 1;
}
@media screen and (max-width: 500px) {
  .topHeaderWrapper {
    flex-direction: column;
  }
  .topHeaderWrapper h1 {
    text-align: center;
  }
  .topHeaderText {
    padding: 0 2em;
    text-align: center;
  }
  .topHeaderWrapperImgWrapper {
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }
  .topHeaderWrapperImg {
    border-radius: 50%;
  }
  .contactDetailLine {
    justify-content: center;
  }
}
.contactDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.contactDetailLine {
  display: flex;
  align-items: center;
  gap: 0.2em;
  color: var(--text-primary);
  padding-top: 0.5em;
}
.contactDetailLine a:hover {
  transition: 0.3s;
}

form {
  /* box-shadow: 0.4em 0.4em 2px 2px rgba(255, 255, 255, 0.2),
    -0.4em -0.4em 2px 2px rgba(255, 255, 255, 0.2); */
  padding: 0 0.5em;
  position: relative;
  background-color: var(--background-primary);
  z-index: 1;
}

.wrapper button[type="submit"],
.responseWrapper button {
  padding: 1.5em;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  border-radius: 4px;
  width: 100%;
}
.wrapper button[type="submit"]:hover,
.responseWrapper button:hover {
  background-color: var(--text-primary);
  color: var(--background-primary);
}

.wrapper button[type="submit"] .spinnerCircle {
  stroke: var(--text-primary);
}
.wrapper button[type="submit"]:hover .spinnerCircle {
  stroke: var(--background-primary);
}

.responseWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.responseMessageWrapper {
  padding: 1em;
  border: 1px solid;
  border-radius: 4px;
  background: var(--background-primary);
  z-index: 1;
  box-shadow: 0px 0px 45px 1px rgba(255, 255, 255, 0.3);
}
