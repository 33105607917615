body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --background-primary: #00050a;
  /* --background-primary: #fffcfc; */
  --text-primary: #fffcfc;
  /* --text-primary: #00050a; */
  --text-secondary: #a4a4a4;
  --error-primary: #ff7373;
}

h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
