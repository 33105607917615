:root {
  --name-first-letter-size: 4rem;
  --name-letters-size: 2rem;
}
.wrapper {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}

.nameAndTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
}
.nameWrapper {
  display: flex;
  flex-direction: row;
  letter-spacing: 0.4em;
  word-break: break-all;
  transition: 0.6s;
  cursor: pointer;
}
@media screen and (max-width: 321px) {
  .nameWrapper {
    letter-spacing: 0.2em;
  }
}
.nameWrapper:hover {
  transition: 0.6s;
  letter-spacing: 0.9rem;
}
.nameWrapperInit {
  transition: 0.6s;
  letter-spacing: 0.9rem;
}

.name {
  font-size: var(--name-letters-size);
}
.name::first-letter {
  font-size: var(--name-first-letter-size);
}

.subtitleWrapper {
  letter-spacing: 0.4rem;
  transition: 0.6s;
  cursor: pointer;
}
.subtitleWrapper:hover {
  letter-spacing: 0.6rem;
  transition: 0.6s;
}

.iconsHeaderWrapper {
  display: flex;
}
.iconsWrapper {
  margin-top: 0.4em;
  display: flex;
  gap: 0em;
  transition: 0.6s;
  width: max-content;
}
.iconsWrapper:hover {
  gap: 1em;
  transition: 0.6s;
  cursor: pointer;
}
