.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-primary);
  z-index: 1;
}

.quoteAndAuthorWrapper {
  /* width: 50%; */
  width: 75%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(10px 10px 10px rgba(255, 255, 255, 1));
  /* box-shadow: 0px 0px 500px -10px rgba(255, 255, 255, 1); */
}
.quoteWrapper {
  display: flex;
}
.quoteWrapper::before,
.quoteWrapper::after {
  content: '"';
  font-size: 2rem;
  padding: 0 0.1em;
}
.quote {
  padding: 1.5em 0;
  letter-spacing: 0.2rem;
  text-align: center;
  font-size: 0.75rem;
}
.quoteAuthor {
  text-align: right;
  font-size: 0.75rem;
  letter-spacing: 0.2rem;
}
.quoteAuthor::before {
  content: "- ";
}
