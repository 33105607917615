.app {
  background-color: var(--background-primary);
  color: var(--text-primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.compass-icon {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  stroke-width: 0.4;
  z-index: 1;
}
.compass-icon * {
  z-index: 1;
}

main {
  display: flex;
  justify-content: center;
  flex: 1;
}
.main-wrapper {
  width: 100%;
  max-width: 750px;
}

rect[data-email-svg="true"] {
  fill: var(--text-primary);
}
path[data-email-svg="true"] {
  stroke: var(--background-primary);
}
