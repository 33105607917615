input:not([type="radio"]),
select,
textarea {
  flex-grow: 1;
  background: transparent;
  border: 0;
  color: var(--text-secondary);
  margin-top: 26px;
  outline: none;
  font-size: 18px;
}
textarea {
  resize: vertical;
  z-index: 1;
}
input:not([type="radio"]):required + label > span::after,
.radioInputLabel[data-cw-input-required="true"]::after {
  content: "*";
  margin-left: 0.2em;
  color: var(--text-primary);
}

.wrapper {
  box-sizing: border-box;
  flex-grow: 1;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.75em;
}

.wrapper input:not([type="radio"]) + label,
.wrapper textarea + label,
.radioInputLabel {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  font-size: 20px;
  padding-top: 6px;
  box-sizing: border-box;
}
.labelTextWrapper {
  /* padding-top: 1em; */
  padding-left: 12px;
}

.radioInputGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 26px;
  gap: 0.2em;
}
.radioInputGroup * {
  font-size: 18px;
}
.radioInputLabel {
  padding-top: 0.225em;
  padding-left: 0.85em;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
}
